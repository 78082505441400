<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
import generalForm from "./form/general-form.vue";
import workingHeightForm from "./form/working-height-form.vue";
import excavationForm from "./form/excavation-form.vue";
import confinedSpaceForm from "./form/confined-space-form.vue";
import energizedForm from "./form/energized-form.vue";
import hotForm from "./form/hot-form.vue";

/**
 * Dashboard Component
 */
export default {
    page: {
        title: "Add Work Permit Request",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
        generalForm,
        workingHeightForm,
        excavationForm,
        confinedSpaceForm,
        energizedForm,
        hotForm,
    },
    data() {
        return {
            title: "Work Permit Request",
            searchDataTable: "",
            filter_work_type: [],
            filter_work_status: [],
            work_type_selected: [],
            work_status_selected: [],
        };
    },
    mounted() {
        let self = this;
        Swal.fire({
            title: '<i class="fas fa-spinner fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
            allowOutsideClick: false,
        });
        var config_type = {
            method: "get",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/perizinan-sub",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.access_token,
            },
        };
        axios(config_type)
            .then((response) => {
                console.log(response, "response data type");
                this.filter_work_type = response.data.data[0].data;
                if (!self.work_type_selected?.mps_nama) {
                    self.filter_work_type.forEach(function (item) {
                        console.log(item.mps_nama);
                        if (item.mps_nama == "General") {
                            self.work_type_selected = item;
                        }
                    });
                }
                Swal.close();
            })
            .catch((e) => {
                this.axiosCatchError = e.response.data.data;
                console.log(this.axiosCatchError);
                Swal.close();
            });
    },
    methods: {},
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" />
        <div class="row">
            <div class="col-lg-12">
                <b-card border-variant="dark" header="Work Permit Information">
                    <b-card-body>
                        <b-form-group label="Work Permit Type" label-for="formrow-nama-role-input">
                            <v-select :options="filter_work_type" label="mps_nama" v-model="work_type_selected" placeholder="Choose Work Permit Type" disabled></v-select>
                        </b-form-group>
                    </b-card-body>
                </b-card>
                <div v-if="work_type_selected.mps_nama === 'General'">
                    <generalForm :data_detail="null" :work_permit_type="work_type_selected" aksi="add" />
                </div>
                <div v-else-if="work_type_selected.mps_nama === 'Working at Height'">
                    <workingHeightForm />
                </div>
                <div v-else-if="work_type_selected.mps_nama === 'Excavation'">
                    <excavationForm />
                </div>
                <div v-else-if="work_type_selected.mps_nama === 'Confined Space'">
                    <confinedSpaceForm />
                </div>
                <div v-else-if="work_type_selected.mps_nama === 'Electrical'">
                    <energizedForm />
                </div>
                <div v-else-if="work_type_selected.mps_nama === 'Hot'">
                    <hotForm />
                </div>
            </div>
        </div>
    </Layout>
</template>
